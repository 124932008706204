import React from 'react'
import { dateConverter } from '../../../../CommonComponent/dateConverter1'

export default function Information({orderDetail, loading}) {
    return (
        <div className='border border-[#DEDEDE] rounded-lg mb-7'>
            <h6 className='text-[#343434] text-[16px] font-[500] leading-normal md:py-5 py-3.5 
                    md:px-10 px-5 border-b border-[#CECECE]'>Information</h6>
            <div className='flex justify-between md:pt-8 pt-5 md:pb-[30px] pb-5 md:px-10 px-5 flex-wrap'>
                <div className='w-[32%] mr-2.5 mb-2.5'>
                    <label className='text-[10px] text-[#343434] font-[500] leading-normal pb-1'>Order number</label>
                    {!loading?
                        <p className='text-[16px] text-[#343434] font-[500] leading-normal'>
                            {orderDetail?.order_number}
                        </p>
                        :
                        <p className='w-[32%] animate-pulse h-10 bg-[#f0efeff3]'></p>
                    }
                </div>
                <div className='w-[32%] mr-2.5 mb-2.5'>
                    <label className='text-[10px] text-[#343434] font-[500] leading-normal pb-1'>Order date</label>
                    {!loading?
                        <p className='text-[16px] text-[#343434] font-[500] leading-normal'>
                            {Object.keys(orderDetail).length>0 && dateConverter(orderDetail?.created_at)}
                        </p>
                        :
                        <p className='w-[32%] animate-pulse h-10 bg-[#f0efeff3]'></p>}
                </div>
                <div className='w-[32%] mb-2.5'>
                    <label className='text-[10px] text-[#343434] font-[500] leading-normal pb-1'>Auction name</label>
                    {!loading?
                        <p id='order_auction_title' className='text-[16px] text-[#343434] font-[500] leading-normal'>
                            {orderDetail?.auction_title}</p>
                        :
                        <p className='w-[32%] animate-pulse h-10 bg-[#f0efeff3]'></p>}
                </div>
            </div>
        </div>  
    )
}
