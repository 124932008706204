import React, { useState } from 'react'
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';
import SetupSection from './setUpSection';

export default function SetUpAuctionforMobile({isOpen, setIsOpen, auctionData, setEditedAuctionData, 
    handleLogoChange, handleLogoRedirectionChange, text, setText,
    handleLogoDelete, errorMessages, deleteMenuLink, 
    setNewMenuLink, setMenuModal,setEditMenu,
    setAuctionData, startDate,setStartDate,endDate,setEndDate,startHour,setStartHour,
    startMin,setStartMin,endHour,setEndHour,
    endMin,setEndMin,mobileModal, isMobileModal,
    logoLoader,setLogoLoader,imageLoader,setImageLoader,invalidDate,setInvalidDate,sameTime,setSameTime,isBanner}) {

    
    return (
        <Drawer
            id="mobile-panel-configuration"
            open={isOpen}
            onClose={()=>setIsOpen((prevState) => !prevState)}
            direction='right'
            className={`bg-[#FFFFFF] mobile-setup ${mobileModal ? 'overflow-hidden': 'overflow-auto'} pb-[65px]`}
        >
            <SetupSection auctionData={auctionData}
                customId='mobile_auction_title'
                customId1='mobile_auction_description'
                date='mobile_auction_start_date'
                setText={setText}
                text={text}
                setEditedAuctionData={setEditedAuctionData}
                invalidDate={invalidDate}
                setInvalidDate={setInvalidDate}
                handleLogoChange={handleLogoChange}
                sameTime={sameTime}
                setSameTime={setSameTime}
                handleLogoRedirectionChange={handleLogoRedirectionChange}
                handleLogoDelete={handleLogoDelete}
                errorMessages={errorMessages}
                deleteMenuLink={deleteMenuLink}
                setNewMenuLink={setNewMenuLink}
                setMenuModal={setMenuModal}
                setAuctionData={setAuctionData}
                startDate={startDate} 
                setStartDate={setStartDate}
                endDate={endDate} 
                setEndDate={setEndDate}
                startHour={startHour}
                setStartHour={setStartHour}
                startMin={startMin}
                setStartMin={setStartMin}
                endHour={endHour}
                setEndHour={setEndHour}
                endMin={endMin}
                setEndMin={setEndMin}
                logoLoader={logoLoader}
                setLogoLoader={setLogoLoader}
                imageLoader={imageLoader}
                isMobileModal ={isMobileModal}
                setImageLoader={setImageLoader}
                setEditMenu={setEditMenu}
                isBanner={isBanner} />
        </Drawer>
    )
}
