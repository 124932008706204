/* eslint-disable max-len */
import React, { Fragment, useEffect, useRef, useState } from 'react'
import "quill-paste-smart";
import ReactQuill from 'react-quill'
import { Listbox, Transition } from '@headlessui/react';
import { currencyValues } from '../../../../../CommonComponent/staticValues';
import ToolTip from '../../../../../CommonComponent/tooltip';
import { Oval } from  'react-loader-spinner'
import config from '../../../../../config';
import { useLocation } from 'react-router-dom';
import disableFieldsBasedOnAuctionStatus from '../../../disableFieldsBasedOnAuctionStatus';
import { QuillModules, RichEditorFormats } from '../../../../../config/quill';

export default function SetUpDetails({auctionData,setAuctionData,setEditedAuctionData,customId,customId1,
    handleLogoChange,handleLogoDelete,errorMessages,logoLoader,setLogoLoader,imageLoader,setImageLoader, text, setText}) {
    const titleRegex = /^(?!\s).*$/; // Don't allow spaces in the beginning
    const state = useLocation();
    const quillRef = useRef(null);
    const [auctionTitleError, setAuctionTitleError] = useState(false)
    const [currencyShow, setCurrencyShow] = useState(state.state===null?false:true)
    const [auctionStatus, setAuctionStatus] = useState(state.state ? 
        ((state.state.status === "Accepting Bids" || state.state.status === "Accepting bids") ? "acceptingBids": state.state.status.toLowerCase())
        : null)

    useEffect(()=>{
        if(text!=='<p><br></p>'){
            setAuctionData(prevData => ({
                ...prevData,
                description: text
            }))
            setEditedAuctionData(prevData => ({
                ...prevData,
                description: text
            }))
        }else{
            setAuctionData(prevData => ({
                ...prevData,
                description: ''
            }))
            setEditedAuctionData(prevData => ({
                ...prevData,
                description: ""
            }))
        }
    },[text])
    
    /**
     * The function `handleProcedureContentChange` is used to update the `description` property of the
     * `auctionData` object based on the provided `content` value.
     */
    const handleProcedureContentChange = (content,delta,source, editor) => {
   
        setText(content);
        
    };

    const handlePaste = (e) => {
        if (quillRef.current) {
            const quill = quillRef.current.getEditor();
            quill.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
                if (node.tagName && node.tagName.toLowerCase() === 'p') {
                    return delta.insert(node.textContent);
                }
                return delta;
            });
        }
    };

    const checkCharacterCount = (event) => {
        const unprivilegedEditor = quillRef.current.unprivilegedEditor;
        if (unprivilegedEditor.getLength() > 2000 && event.key !== 'Backspace')
            event.preventDefault();
    };

    return (
        <div className="details-content">
            {auctionData.template_name!=="Single Lot" && <div>
                <span className="flex pt-4">
                    <p className="font-medium text-[#343434] text-[12px] leading-normal">Lead auction image</p>
                    <ToolTip trigger="click"
                        content="Images must be JPEG, PNG or GIF format, optimised for web viewing and no larger than 5MB.">
                        <img src="/images/info-icon.svg" className="pl-2 cursor-pointer" alt="info-icon" loading="lazy" />
                    </ToolTip>
                </span>
                {auctionData.auction_image==='' && <div className={`w-full flex justify-center relative bg-[#E6E6E6] py-[22px] px-3 mt-2 rounded-md${disableFieldsBasedOnAuctionStatus[`${auctionStatus}`]?.auction_image ? " pointer-events-none opacity-60" : " cursor-pointer"}`}>
                    <img src="/images/add-image-icon.svg" alt="logo-preview" loading="lazy" />
                    <label htmlFor="auctionUpload" className="font-medium text-center pl-2 text-[#343434] text-[10px] leading-normal">{auctionData.auction_image ? "Change logo" : "Add image"}</label>
                    <input 
                        type="file"
                        accept="image/jpeg, image/png, image/gif"
                        id="auctionUpload"
                        className="opacity-0 z-10 absolute cursor-pointer top-0 h-[58px] w-full"
                        onChange={(e)=>handleLogoChange(e, "auction_image")}
                    />
                    {imageLoader&& <Oval
                        height={20}
                        width={50}
                        color="#FFFFFF"
                        wrapperClass=""
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor="#000000"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                    />}
                </div>}
                {auctionData.auction_image!==''&&
                <div className={`w-full flex h-[84px] justify-center items-center relative bg-[#E6E6E6] 
                    px-3 mt-2 rounded-md${disableFieldsBasedOnAuctionStatus[`${auctionStatus}`]?.auction_image ? " pointer-events-none opacity-60" : ""}`}>
                    <img src={`${config.Storage.CDN}${auctionData.auction_image}`} alt="add-image-icon" className='w-[125px] h-[65px]' loading="lazy" />
                    {imageLoader && <div className='absolute left-[43%]' ><Oval
                        height={25}
                        width={55}
                        color="#FFFFFF"
                        wrapperClass=""
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor="#000000"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                    /></div>}
                    <div className="flex justify-end absolute right-[15px] bottom-[15px] z-[11]">
                        <label htmlFor="auctionUpload" className="font-medium text-center pr-2 h-auto text-[#343434] text-[10px] leading-normal cursor-pointer">
                            <img src="/images/edit-icon-img.svg" className="cursor-pointer w-3.5" alt="edit" loading="lazy" />
                        </label>
                        <input 
                            type="file"
                            accept="image/jpeg, image/png, image/gif"
                            id="auctionUpload"
                            className="opacity-0 absolute cursor-pointer top-0 w-3 left-0 z-[-1]"
                            onChange={(e)=>handleLogoChange(e,'auction_image')}
                        />
                        <img src="/images/delete-icon-img.svg"  id ="delete-icon" className="cursor-pointer" alt="delete" onClick={()=>handleLogoDelete("auction_image")} loading="lazy" />
                    </div>
                </div>}
                {errorMessages.auction_image_error!=='' && <p className='text-[10px] pt-1.5 font-medium leading-normal error-msg text-[#984848]'>{errorMessages.auction_image_error}</p>}
            </div>}
            <div className="pt-3">
                <label for="auction_title" className="font-medium text-[#343434] text-[12px] leading-normal">Title</label>
                <input autoComplete='off' type="text" value={auctionData.title} data-testid={customId} id='auction_title' className="border border-[#A1A1A9] w-full px-3 py-3 outline-none text-[14px] leading-normal 
                font-medium text-[#343434] placeholder:text-[#A1A1A9]" placeholder="Auction title" maxLength={120} onChange={(e)=>{e.preventDefault();
                    if(titleRegex.test(e.target.value)) {
                        setAuctionData(prevData => ({
                            ...prevData,
                            title: e.target.value,
                        }));
                        setEditedAuctionData(prevData => ({
                            ...prevData,
                            title: e.target.value,
                        }))
                    }else{
                        return;
                    }
                }
                } />
                {auctionTitleError && <p className='text-[10px] pt-1.5 font-medium leading-normal error-msg text-[#984848]'>Title can only contain letters and spaces</p>}
            </div>
            <div className="py-3">
                <label for="logo-rediection" className="font-medium text-[#343434] text-[12px] leading-normal">Description</label>
                <div className="pt-2 auction-description" data-testid={customId1} id="auction_description">
                    <ReactQuill
                        value={text}
                        theme="snow"
                        ref={quillRef}
                        modules={QuillModules}
                        formats={RichEditorFormats}
                        onKeyDown={checkCharacterCount}
                        placeholder="Auction description"
                        onChange={handleProcedureContentChange}
                        className={customId}
                        style={{}}
                    >
                    </ReactQuill>
                </div>
            </div>
            {/* <!-- dropdown FOR CURENCY --> */}
            <div className="relative  w-full  mb-[15px]">
                <div className="flex pb-1">
                    <label for="country" className="text-[12px] font-medium leading-normal text-[#343434]">Currency</label>
                    <ToolTip trigger="click"
                        content="Please choose the currency for your auction. This is the currency your bidders will bid and be charged in.
                            Dropdown list:
                            1. GBP
                            2. USD
                            3. EUR
                            4. HKD
                            5. JPY
                            6. CHF
                            7. SGD
                            8. AUD
                            9. CAD
                            10. INR">
                        <img src="/images/info-icon.svg" className="pl-2 cursor-pointer" alt="info-icon" loading="lazy" />
                    </ToolTip>
                </div>
                <Listbox disabled={disableFieldsBasedOnAuctionStatus[`${auctionStatus}`]?.currency} value={auctionData.currency} 
                    onChange={(value)=>{
                        setAuctionData(prevData=>(setCurrencyShow(true),{...prevData, currency: value.name}))
                        setEditedAuctionData(prevData=>(setCurrencyShow(true),{...prevData, currency: value.name}))
                    
                    }}>
                    {({ open }) => (
                        <>
                            <Listbox.Button className={`text-[14px] text-start relative w-full h-11 px-3 border disabled:border-[#c9c9cc] disabled:text-[#A1A1A9] disabled:bg-[#F4F4F5] border-[#A1A1A9] ${currencyShow?'text-[#343434]':'text-[#A1A1A9]'} outline-none font-medium leading-normal`}  id="currency">{auctionData.currency}</Listbox.Button>
                            <img src="/images/dropdown-icon.png" alt="dropdown-icon" className={`absolute top-[40px] right-[15px] transition-all ${open?'rotate-180':'transform-none'}`} loading="lazy" />
                            <Listbox.Options className="bg-white rounded-[8px] z-10  absolute w-full  [&[data-te-dropdown-show]]:block 
                    shadow-bs1 list-none">
                                <Transition
                                    as={Fragment}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className='pl-[18px] pr-[15px] py-2 h-[155px] customScrollbar overflow-y-auto overflow-x-hidden 
                            text-[12px]  leading-normal font-medium text-[#343434]'>
                                        {currencyValues.map((currency) => (
                                            /* Use the `active` state to conditionally style the active option. */
                                            /* Use the `selected` state to conditionally style the selected option. */
                                            <Listbox.Option key={currency.id} value={currency} as={Fragment} className="pr-[15px] py-1 list-none">
                                                {({ active, selected }) => (
                                                    <li
                                                        className={`py-2 cursor-pointer rounded-[11px] px-2.5 ${
                                                            active ? 'bg-[#E6E6E6]' : 'bg-white text-[#343434]'
                                                        }`} data-testid={`currency-${currency.name}`}
                                                    >
                                                        {currency.name}
                                                    </li>
                                                )}
                                            </Listbox.Option>
                                        ))}
                                    </div>
                                </Transition>
                            </Listbox.Options>
                        </>
                    )}
                </Listbox>
            </div>
        </div>  
    )
}
