/* eslint-disable security/detect-unsafe-regex */
/* eslint-disable max-len */
import { currencySymbolMapping } from "../../../../CommonComponent/staticValues";
import { Menu } from "@headlessui/react";
import config from "../../../../config";
import { motion } from 'framer-motion'
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";


export default function LotSingle(props){

    const {lot,setLotNumber,setLotTitle, index, auctionData, setLotDetailsFromSocket, 
        lotDetailsFromSocket, onDragStart, onDragEnd, onDragEnter, setDeleteLotModal, isSocket} = props;

    const Navigate=useNavigate();
    const {id,uid}=useParams();
    const [topBidder,setTopBidder]=useState((!lot.top_bidder || lot.top_bidder=="") ? "-" : lot.top_bidder)
    const [paddleNumber, setPaddleNumber]=useState((!lot.paddle_number || lot.paddle_number=="") ? "-" : lot.paddle_number)
    const [currentBid, setCurrentBid]=useState((!lot.current_bid || lot.current_bid=="") ? "-" : lot.current_bid)
    const disableLotDelete = ['Cancelled' , 'Completed', 'Accepting bids', 'Published'].includes(auctionData.status)
    const disableLotEdit = ['Cancelled' , 'Completed'].includes(auctionData.status)

    /* The `useEffect` hook is used to perform side effects in a React component. In this case, the
    effect is triggered whenever the `lot` object changes. */
    useEffect(()=>{
        setTopBidder((!lot.top_bidder || lot.top_bidder=="") ? "-" : lot.top_bidder);
        setPaddleNumber((!lot.paddle_number || lot.paddle_number=="") ? "-" : lot.paddle_number);
        setCurrentBid((!lot.current_bid || lot.current_bid=="") ? "-" : lot.current_bid);
    },[lot])

    /**
     * The function `fetchDataFromSocket` updates the state variables `topBidder`, `paddleNumber`, and
     * `currentBid` based on the data received from a socket connection.
     */
    const fetchDataFromSocket=()=>{

        if(Object.keys(lotDetailsFromSocket).length > 0 ){

            if(lotDetailsFromSocket[lot._id]?.top_bidder){
                setTopBidder(lotDetailsFromSocket[lot._id].top_bidder == " " ? '-' : lotDetailsFromSocket[lot._id].top_bidder)
            }
            if(lotDetailsFromSocket[lot._id]?.paddle_number){
                setPaddleNumber(lotDetailsFromSocket[lot._id].paddle_number)
            }
            if(lotDetailsFromSocket[lot._id]?.bid_amount){
                setCurrentBid(lotDetailsFromSocket[lot._id].bid_amount)
            }
        }

    }

    useEffect(()=>{
        fetchDataFromSocket()
    },[lotDetailsFromSocket])

    return(
        <motion.tr className="border-b  border-[#DEDEDE] table-row h-14 " 
            key={index}
            id= "list-items"
            initial={{ opacity: 0, y: 0 }}
            animate={{ opacity: 1, y: 0 }}
            onDragStart={() => onDragStart(index)}
            onDragEnter={() => onDragEnter(index)}
            onDragEnd={onDragEnd}
            // TODO: Fix Reorder issue and enable this feature again.
            // draggable
            transition={{ duration: 0.3, delay: index * 0.002 }}>
            <td className=" text-[#343434]  px-4 py-3 text-[14px] leading-normal font-medium text-center">
                {lot.lot_number}</td>
            <td><img 
                        
                src={lot?.images ? ((lot?.images.length===0) ? "/images/img-lotlist.png" : 
                    `${config.Storage.CDN}${lot.images.find((image) => image.featured).url}`) : "/images/img-lotlist.png"}
                className=" px-4 py-3 w-[95px] h-[77px]" alt="img"/></td>
            <td className=" text-[#343434] px-4 py-3 text-[14px] text-center leading-normal font-medium cursor-pointer hover:underline" 
                onClick={()=>Navigate(`/auctions/${id}/${auctionData.auction_id}/lots/view/${lot.lot_number}`, { state: isSocket})}>
                {lot.title1.length > 20 ? lot.title1.substring(0, 20) + '...' : lot.title1}</td>
            <td className=" text-[#343434] px-4 py-3 text-[14px] text-center leading-normal font-medium">
                {(lot.starting_price===0 || lot.starting_price===undefined) ? '-' : Number(lot.starting_price).toLocaleString(auctionData.currency ==='INR' ? 'en-IN' : 'en-US', {
                    style: 'currency',
                    currency: auctionData.currency,
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                })}
            </td>
            <td className=" text-[#343434] px-4 py-3 text-[14px] text-center leading-normal font-medium">
                {currentBid=="-" ? currentBid : Number(currentBid).toLocaleString(auctionData.currency ==='INR' ? 'en-IN' : 'en-US', {
                    style: 'currency',
                    currency: auctionData.currency,
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                })}
            </td>
            <td className=" text-[#343434] px-4 py-3 text-[14px] text-center leading-normal font-medium">
                {topBidder}</td>
            <td className=" text-[#343434] px-4 py-3 text-[14px] text-center leading-normal font-medium">
                {paddleNumber}</td>
            <td className=" sm:relative  sm:bg-transparent bg-[#fff]  sticky top-0 right-0">
                <div className=" sm:px-4 px-2 h-fit py-[43px] sm:shadow-none shadow-bs7 ">
                    <span className="flex justify-end ">
                        <Menu as="div" className="relative" >
                            <Menu.Button>
                                <img src="/images/three-dots-icon-black.svg" alt="img" 
                                    className="cursor-pointer three-dots-icon" type="button"
                                    id="action-lot"
                                    data-testid={`action-${index}`} />
                            </Menu.Button>
                            <Menu.Items>
                                <div style={{"top" : "-80px"}} className="dropdown-cont absolute overflow-hidden rounded-[10px] float-left 
                                                    m-0 w-[150px] py-2 px-2 min-w-max right-0 z-[50] 
                                                    list-none  border-none bg-white bg-clip-padding 
                                                    text-left text-base shadow-bs3 ">
                                    <Menu.Item>
                                        <button  data-testid="view-lot" onClick={()=>Navigate(`/auctions/${id}/${uid}/lots/view/${lot.lot_number}`, { state: isSocket})}
                                            className="disabled:text-[hsl(240,4%,65%)] disabled:cursor-auto text-left block w-full whitespace-nowrap bg-transparent pl-3 pr-5 py-2 
                                                            text-[12px] font-medium text-[#343434] cursor-pointer hover:bg-[#E6E6E6] hover:rounded-[11px]"
                                        >View bids</button>
                                    </Menu.Item>
                                    <Menu.Item>
                                        <button data-testid='update-lot' disabled={disableLotEdit}
                                            onClick={()=>Navigate(`/auctions/${id}/${auctionData.auction_id}/lots/${lot.lot_number}`,
                                                {state : auctionData})}
                                            className="block w-full whitespace-nowrap bg-transparent pl-3 pr-5 py-2 hover:bg-[#E6E6E6] hover:rounded-[11px]
                                                text-[12px] font-medium text-[#343434] text-left cursor-pointer disabled:text-[hsl(240,4%,65%)] disabled:cursor-auto"
                                        >Edit</button>
                                    </Menu.Item>
                                    <Menu.Item>
                                        <button disabled={disableLotDelete}
                                            onClick={()=>{setDeleteLotModal(true); setLotNumber(lot.lot_number);
                                                setLotTitle(lot.title1) }}
                                            className="block w-full whitespace-nowrap bg-transparent pl-3 pr-5 hover:bg-[#E6E6E6] hover:rounded-[11px]
                                                            py-2 text-[12px] font-medium text-[#343434] disabled:text-[hsl(240,4%,65%)] text-left"
                                            data-testid="delete-lot"
                                        >Delete</button>
                                    </Menu.Item>
                                </div>
                            </Menu.Items>
                        </Menu>
                    </span>  
                </div> 
            </td>
        </motion.tr>
    )
}