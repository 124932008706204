/* eslint-disable security/detect-object-injection */
/* eslint-disable max-len */
import React from 'react'
import { Disclosure } from '@headlessui/react'
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import config from '../../../../config'
import { selectFontStyle } from '../../../../CommonComponent/SelectFontStyle'

export default function LayoutNavbar({auctionData}) {
    // Default menu link names
    const defaultMenuLinks = ['Link 1', 'Link 2', 'Link 3', 'Link 4','Link 5', 'Link 6', 'Link 7'];

    // Combine custom links from menu_links and fill in with default links
    const combinedLinks = defaultMenuLinks.map((defaultLink, index) => {
        if (index < auctionData.menu_links.length) {
            return auctionData.menu_links[index].title;
        } else {
            return defaultLink;
        }
    });
    return (
        <nav
            className="relative flex w-full flex-nowrap items-center justify-between py-2 lg:flex-wrap shadow-bs4 lg:py-4"
            style={{backgroundColor: auctionData.header['background_color'],color: auctionData.header['text_color'] }}
            data-te-navbar-ref>
            <Disclosure as="div" className="flex w-full flex-wrap items-center justify-between px-3">
                <div className="ml-2">
                    <div className={`w-[71px] mr-[100px] h-[41px] flex justify-center items-center ${auctionData.logo_image===""?"bg-[#E6E6E6]":""}`}>
                        {auctionData.logo_image===""?
                            <p className="text-center py-[14px] text-[8px] text-[#52525A] leading-normal font-medium">Logo</p>:
                            <img id='logoUpload' src={`${config.Storage.CDN}${auctionData.logo_image}`} alt='image' loading='lazy' className='w-[71px] h-[41px] object-contain' />}
                    </div>
                </div>
                <ul
                    className="list-style-none mx-auto hidden lg:flex flex-col pl-0 lg:flex-row" 
                    style={{fontFamily: selectFontStyle(auctionData.font['body_font'])}}
                    data-te-navbar-nav-ref>
                    {combinedLinks.map((linkName, index) => (
                        <>
                            {linkName!=='Link 5'&& linkName!=='Link 6'&&linkName!=='Link 7' &&
                            <li id='updated-menu-title' data-testid={`updated-menu-title-${index}`}
                                key={`link-${index}`}
                                
                                className={`relative my-4 flex items-center pl-2 lg:my-0 xl:px-5 px-3
                                     overflow-hidden whitespace-nowrap text-ellipsis`}
                                data-te-nav-item-ref>
                                <a
                                    className="text-[11px] cursor-pointer leading-normal 
                                    text-[#282828] lg:px-2 line-clamp-1 truncate"
                                    style={{color: auctionData.header['text_color'],fontFamily: selectFontStyle(auctionData.font['body_font'])}}
                                    aria-current="page"
                                    data-te-nav-link-ref>
                                    {linkName}
                                </a>
                            </li>}
                            {auctionData.menu_links.length > 0 &&
                            auctionData.menu_links[index] &&
                            auctionData.menu_links[index].dropdown.length > 0 &&
                            auctionData.menu_links[index].dropdown[0].title!=='' ? 
                                (
                                    <Menu className='relative flex items-center' as="div">
                                        {({ open }) => (
                                            <>
                                                <Menu.Button className="w-2.5 -ml-4">
                                                    <img
                                                        src="/images/dropdown-icon.png"
                                                        alt="dropdown-icon"
                                                        className={`cursor-pointer ${open?'rotate-180':''}`}
                                                        loading="lazy"
                                                    />
                                                </Menu.Button>
                                                <Transition
                                                    as={Fragment}
                                                    enter="transition ease-out duration-100"
                                                    enterFrom="transform opacity-0 scale-95"
                                                    enterTo="transform opacity-100 scale-100"
                                                    leave="transition ease-in duration-75"
                                                    leaveFrom="transform opacity-100 scale-100"
                                                    leaveTo="transform opacity-0 scale-95"
                                                >
                                                    <Menu.Items className='w-[125px] bg-[#FFFFFF] absolute top-[25px] shadow-bs1 -left-[100px] rounded-[9px] p-2.5'>
                                                        {auctionData.menu_links[index].dropdown.map((dropdownItem, dropdownIndex) => (
                                                            <Menu.Item as="div" key={dropdownIndex} className="text-[11px] font-medium">{dropdownItem.title}</Menu.Item>
                                                        ))}
                                                    </Menu.Items>
                                                </Transition>
                                            </>
                                        )}
                                    </Menu>
                                ) : null}
                        </>
                    ))}
                </ul>
                <div className="flex items-center">
                    {/* Hamburger button for mobile view */}
                    <ul className="md:flex hidden justify-end items-center">
                        <li>
                            <button className="cursor-default bg-[#282828] rounded-[4px] text-[9px] text-[#fff] leading-normal font-medium px-[21px] py-[8px] md:w-[93px]" style={{backgroundColor: auctionData.buttons['background_color'],color: auctionData.buttons['text_color'],fontFamily: selectFontStyle(auctionData.font['body_font'])}}>Sign In</button>
                        </li>
                        <li className="ml-3">
                            <button className="cursor-default bg-[#282828] rounded-[4px] text-[9px] text-[#fff] leading-normal font-medium px-[5px] py-[8px] md:w-[100px]"  style={{backgroundColor: auctionData.buttons['background_color'],color: auctionData.buttons['text_color'],fontFamily: selectFontStyle(auctionData.font['body_font'])}}>Create Account</button>
                        </li>
                    </ul>
                    <Disclosure.Button id="menu-icon"
                        className="block border-0 bg-transparent px-2 text-[#343434] lg:hidden"
                        type="button">
                        {/* Hamburger icon */}
                        <span className="[&>svg]:w-7">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                className="h-7 w-7">
                                <path
                                    fill-rule="evenodd"
                                    d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
                                    clip-rule="evenodd" />
                            </svg>
                        </span>
                    </Disclosure.Button>
                </div>
                {/* <!-- Collapsible navbar container --> */}
                <Disclosure.Panel
                    className="mt-2 flex-grow justify-center basis-[100%] items-center 
                    lg:mt-0 lg:hidden lg:basis-auto absolute top-[49px] left-0 w-full bg-[#FFFFFF] px-3 shadow-bs4 py-2"
                    id="navbarSupportedContent3"
                    data-te-collapse-item>
                    {/* <!-- Left links --> */}
                    <ul
                        className="list-style-none mx-auto  flex flex-col pl-0  lg:flex-row"
                        data-te-navbar-nav-ref>
                        {combinedLinks.map((linkName, index) => (
                            linkName!=='Link 5'&& linkName!=='Link 6'&&linkName!=='Link 7' &&
                            <Disclosure  as="div"
                                key={`links-${index}`}
                                className={`relative mb-4 lg:mb-0 pl-2 w-fit lg:px-5`}
                                data-te-nav-item-ref>
                                {({ open }) => (
                                    <>
                                        <a
                                            className="text-[11px] w-fit cursor-pointer font-medium leading-normal text-[#282828] lg:px-2 line-clamp-1"
                                            style={{color: auctionData.header['text_color']}}
                                            aria-current="page"
                                            data-te-nav-link-ref>
                                            {linkName}
                                        </a>
                                        {auctionData.menu_links.length > 0 &&
                                auctionData.menu_links[index] &&
                                auctionData.menu_links[index].dropdown.length > 0 ? 
                                            (
                                                <div className='flex items-center'>
                                                    <>
                                                        <Disclosure.Button className="w-2.5 ml-2 absolute top-2.5 -right-6">
                                                            <img
                                                                src="/images/dropdown-icon.png"
                                                                alt="dropdown-icon"
                                                                className={`cursor-pointer ${open?'rotate-180':''}`}
                                                                loading="lazy"
                                                            />
                                                        </Disclosure.Button>
                                                        <Transition
                                                            as={Fragment}
                                                            enter="transition ease-out duration-100"
                                                            enterFrom="transform opacity-0 scale-95"
                                                            enterTo="transform opacity-100 scale-100"
                                                            leave="transition ease-in duration-75"
                                                            leaveFrom="transform opacity-100 scale-100"
                                                            leaveTo="transform opacity-0 scale-95"
                                                        >
                                                            <Disclosure.Panel className='w-full p-2.5'>
                                                                {auctionData.menu_links[index].dropdown.map((dropdownItem, dropdownIndex) => (
                                                                    <p as="div" className='text-[11px]' key={dropdownIndex}>{dropdownItem.title}</p>
                                                                ))}
                                                            </Disclosure.Panel>
                                                        </Transition>
                                                    </>
                                                </div>
                                            ) : null
                                        }
                                    </>
                                )}
                            </Disclosure>
                            
                        ))}
                    </ul>
                    <ul className="lg:flex md:hidden flex  lg:justify-end items-center">
                        <li>
                            <button id="sign-in btn" className=" cursor-default bg-[#282828] rounded-[4px] 
                            text-[9px] text-[#fff] leading-normal px-[21px] font-medium py-[8px] md:w-[93px]" style={{backgroundColor: auctionData.buttons['background_color'], color: auctionData.buttons['text_color']}}>Sign In</button>
                        </li>
                        <li className="ml-3">
                            <button id="create-auction-btn" className=" cursor-default bg-[#282828] rounded-[4px] 
                            text-[9px] text-[#fff] leading-normal px-[5px] py-[8px] md:w-[100px] font-medium" style={{backgroundColor: auctionData.buttons['background_color'], color: auctionData.buttons['text_color']}}>
                                Create Account</button>
                        </li>
                    </ul>
                </Disclosure.Panel>
            </Disclosure>
        </nav>    
    )
}
