/* eslint-disable max-len */
import React, { useCallback, useEffect, useState } from 'react'
import { Auth } from 'aws-amplify'
import { logout } from '../../pages/auth/registrationSlice'
import { useDispatch, useSelector } from 'react-redux'
import { dataService } from '../../services/dataService'
import { useLocation, useNavigate, Link, useParams } from 'react-router-dom';
import { Menu } from '@headlessui/react';
import { clearData } from '../../pages/account/profileSlice'
import { updatePricingValue } from '../../pages/Pricing/pricingSlice';
import Cookies from 'js-cookie';

export default function Sidebar({isBanner,ismdBanner}) {
    const params = useParams()
    const dispatch = useDispatch()
    const path = useLocation();
    const Navigate = useNavigate();
    const [planType,setPlanType]=useState("");
    const [lot,setLot]=useState({});
    const [auctionData,setAuctionData]=useState({});

    const [name,setName]=useState('');
    const user = (localStorage.getItem('user'));
    const nameFromRedux=useSelector((state)=>state.name);
   
    const handleView = async (auction_id) => {
        const data = await dataService.viewAuction(`?auction_id=${auction_id}`)
        if(data.error===false){
            setAuctionData(data.data.data)
        }
    }

    const fetchLots=async()=>{
        let endpoint = `?auction_id=${params.uid}`
        const response = await dataService.LotList(endpoint);
        const data=response.data.data;
        setLot(data.find((item)=>item.lot_number==params.lotId))
    }


    useEffect(()=>{
        if(params.lotId && params.id){
            handleView(params.id)
            fetchLots()
        }else if(params.id){
            handleView(params.id)
        }
    },[])



    /* The `fetchHandler` function is a callback function that is created using the `useCallback` hook.
    It is responsible for fetching the user's profile data from the server and updating the `name`
    state variable with the fetched data. */
    const fetchHandler = useCallback(async()=>{
        const body = {
            "email": user
        }
        
        const response = await dataService.ViewProfile(body);
        localStorage.setItem('userData', JSON.stringify(response.data?.data?.data))
        const plan = response.data.data.data.plan_type===undefined ? 'Pro' : (response.data.data.data.free_user===true ? 'Free' : response.data.data.data.plan_type)
        dispatch(updatePricingValue({ field: 'plan_type', value: "Pro"}))  //default plan is Pro for time being INDY-12
        localStorage.setItem('plan_type',  JSON.stringify(plan))
        setName(`${response.data.data.data.first_name} ${response.data.data.data.last_name}`)
        setPlanType(plan)
        
        // const response = await dataService.ViewProfile(body);
        // setName(response.data.data.data.first_name+ " " + response.data.data.data.last_name);
        // setPlanType(response.data.data.data.plan_type===undefined ? (response.data.data.data.free_user===true && 'Free' ) :  response.data.data.data.plan_type)
    },[])

    const  clickHandler=()=>{
        if(!localStorage.getItem('plan_type')){
            fetchHandler()
        }else{
            setPlanType(localStorage.getItem('plan_type'));
        }
    }

    /* The `useEffect` hook is used to perform side effects in functional components. In this case, the
    `useEffect` hook is used to call the `fetchHandler` function when the component mounts or when
    the `fetchHandler` function changes. */
    useEffect(()=>{
        
        fetchHandler();
    },[fetchHandler])

    // const toDashboard=()=>{
    //     sessionStorage.removeItem('storedRoute');
    // }


    const deleteCognitoCookie = () => {
        const cookieName = 'cognito';
        const path = '/';

        // Get the domain from environment variables
        const domain = window.location.hostname;

        // Delete the cookie
        document.cookie = `${cookieName}=; Path=${path}; Domain=${domain}; Expires=Thu, 01 Jan 1970 00:00:00 GMT;`;
    };




    /**
     * The function `handleSignOut` removes a stored route from local storage, signs the user out,
     * navigates to the home page, and dispatches a logout action.
     */
    const handleSignOut = async()=>{
        //sessionStorage.removeItem('storedRoute');
        localStorage.clear();
        await Auth.signOut()
        dispatch(clearData())
        dispatch(logout())
        Navigate('/login')
    }

    //    top-[48px] md:top-[34px] mt-4
    
    return (
        <div className={`h-20 sm:bg-[#FFFFFF] bg-[#282828] border-b border-[#D4D4D8] sticky  ${isBanner ? 'top-0 mt-0': 'top-[45px] mt-4'} ${ismdBanner ? 'md:top-0':'md:top-[34px]'}  left-0 z-20 `}>
            <nav className="flex items-center justify-between  sm:px-10 px-5 py-4 h-20">
                <ul>
                    <li className="sm:flex items-center hidden">
                        <Link to="/"><img src="/images/home-icon.svg" alt="home-icon" loading="lazy" /></Link>
                        {path.pathname==='/account' &&
                        <p className="font-medium pl-2 text-[#A1A1A9] text-[12px] pt-1 leading-normal"> 
                            &gt; Account</p>}
                        {path.pathname==='/allsales' &&
                            <p className="font-medium pl-2 text-[#A1A1A9] text-[12px] pt-1 leading-normal"> 
                                &gt; All Sales</p>}
                        {path.pathname==='/edit' &&
                        <p className="font-medium pl-2 text-[#A1A1A9] text-[12px] pt-1 leading-normal"> 
                            &gt; Account</p>}
                        {path.pathname.includes('/auctions') &&
                        <button type="button" >
                            <div className='flex'>
                                <p className="font-medium pl-2 text-[#A1A1A9] text-[12px] pt-1 leading-normal" onClick={()=>Navigate('/auctions')} > 
                                    &gt; Auctions</p>
                                {params.bidderId && <p className="font-medium pl-2 text-[#A1A1A9] text-[12px] pt-1 leading-normal"> 
                                    &gt; Bidder Information</p>}
                                {(params.lotId && params.uid) && <p className="font-medium pl-2 text-[#A1A1A9] text-[12px] pt-1 leading-normal" onClick={()=>Navigate(`auctions/${params.id}/${params.uid}/lots`)} > 
                                    &gt; {auctionData?.title?.length > 20 ? auctionData?.title?.substring(0, 20) + '...' : auctionData?.title}  &gt; {lot?.title1}</p>}
                                {(!params.lotId && params.uid) && <p className="font-medium pl-2 text-[#A1A1A9] text-[12px] pt-1 leading-normal" 
                                > 
                                    &gt; {auctionData?.title?.length > 20 ? auctionData?.title?.substring(0, 20) + '...' : auctionData?.title}
                                </p>}
                            </div>
                        </button>}
                        {path.pathname==='/allbidders' &&
                        <p className="font-medium pl-2 text-[#A1A1A9] text-[12px] pt-1 leading-normal"> 
                            &gt; All Bidders</p>}
                    </li>
                    <li className="flex items-center sm:hidden ">
                        <Link to="/"><img src="/images/indy-logo.svg" alt="indy-icon" loading="lazy" /></Link>
                    </li>
                </ul>
                <ul className="flex ml-auto sm:border-l-2  border-[#D4D4D8]">
                    <li>
                        <a className="flex items-center pl-5 justify-between ">
                            <img src="/images/profile-icon.svg" alt="profile-icon" loading="lazy" className='w-10 h-10' />
                        </a>
                    </li>
                    <li className="sm:flex items-center hidden px-3">
                        <p className="font-medium text-[#343434] text-[14px]">{nameFromRedux=="" ? (name.length>20?name.substring(0,20)+'....':name) : (nameFromRedux.length >20 ? nameFromRedux.substring(0,20)+'...': nameFromRedux )}</p>
                    </li>
                    <Menu as="div" className="relative mt-3" >
                        <Menu.Button>
                            <img src="/images/three-dots-icon-black.svg" alt="threedots" className="sm:block hidden" id="profile_menu"
                                loading="lazy" onClick={()=>clickHandler()} />
                            <img src="/images/three-dots-white.svg" alt="threedots" className="sm:hidden block" loading="lazy" onClick={()=>clickHandler()} />
                        </Menu.Button>
                        <Menu.Items as='div' className="absolute overflow-hidden rounded-[10px] top-auto float-left 
                                                    my-[12px] w-[147px] py-2 min-w-max right-0 z-[50]
                                                    list-none  border-none bg-white bg-clip-padding 
                                                    text-left text-base shadow-bs3 pl-5 pr-7 text-[12px]">
                            <Menu.Item data-testid='view-auction' as='div' className=" p-2 border-b border-[#E4E4E7]">
                                {planType.replace(/^"|"$/g, '')} plan
                                <button type='button' onClick={()=>Navigate('/pricing')}
                                    className="block w-full whitespace-nowrap bg-transparent  
                                                            text-[10px] font-medium text-[#343434] text-left">
                                    View pricing</button>
                            </Menu.Item>
                            <Menu.Item className="hover:bg-[#E6E6E6] hover:rounded-[11px] p-2 mt-3" onClick={()=>Navigate('/account?active=profile')} >
                                <button className='w-full text-left' >View profile</button>
                            </Menu.Item>
                            <Menu.Item className="hover:bg-[#E6E6E6] hover:rounded-[11px] p-2" >
                                <button className='w-full text-left' onClick={(e)=>handleSignOut(e)} data-testid="logout_button" >
                                    Logout
                                </button>
                            </Menu.Item>
                        </Menu.Items>
                    </Menu>
                    {/* <li id="logout-btn" className="flex items-center cursor-pointer" onClick={(e)=>handleSignOut(e)}>
                        <a>
                            <img src="/images/three-dots-icon-black.svg" alt="threedots" className="sm:block hidden" 
                                loading="lazy" />
                            <img src="/images/three-dots-white.svg" alt="threedots" className="sm:hidden block" loading="lazy" />
                        </a>
                    </li> */}
                </ul>
            </nav>
        </div>    
    )
}
