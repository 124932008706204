import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';

export default function ToolTip({ content, children, position="bottom", ...props }){

    return(
        <Tooltip
            title={content}
            position={position}
            arrow="true"
            arrowSize="regular"
            {...props}
        >
            {children}
        </Tooltip>
    );
}